import { Component, OnInit, ViewChildren, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminServices } from '../services/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  formInput = ['otp1', 'otp2', 'otp3', 'otp4'];
  @ViewChildren('formRow') rows: any;
  otpForm: any;
  otpSent = true;
  failOTP = false;
  otpSentMsg: string;
  userRequestId: any;
  userDetails: any;
  otpAPIRequest: any = {};
  loginSuccess = false;
  timeExpire = false;
  timeExpireErr: string;

  constructor(
    private router: Router,
    private adminServices: AdminServices,
  ) {
    this.otpForm = this.otpFormGroup(this.formInput);
  }

  ngOnInit() {
    this.otpSentMsg = 'OTP sent successfully';
    this.userRequestId = this.adminServices.getRequestId().subscribe((data) => {
      if (data.requestId === undefined) {
        this.router.navigate(['/admin-login']);
      } else {
        this.userDetails = data;
      }
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.userRequestId.unsubscribe();
  }

  otpFormGroup(elements) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event, index) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  onSubmit() {
    if (this.otpForm.value) {
      this.otpAPIRequest = {
        requestId: this.userDetails.requestId,
        otp: Object.values(this.otpForm.value).join('')
      };
    }
    this.adminServices.verify2FAOTP(this.otpAPIRequest).subscribe((response) => {
      if (response && response.code === 200) {
        this.otpSent = false;
        this.failOTP = false;
        this.loginSuccess = true;
        // tslint:disable-next-line: no-string-literal
        this.adminServices.setUserDetails(response['result']);
        // tslint:disable-next-line: no-string-literal
        localStorage.setItem('token', response['result'].accessToken);
        // tslint:disable-next-line: no-string-literal
        localStorage.setItem('role', response['result'].adminDetails.role);
        if (response['result'].adminDetails.role == 'superAdmin') {
          setTimeout(() => {
            this.router.navigate(['/administration']);
          }, 2000);
        }
        else if(response['result'].adminDetails.role == 'operationalManager' || response['result'].adminDetails.role == 'relationshipManager'){
          setTimeout(() => {
            this.router.navigate(['/application-management']);
          }, 2000);
        }

      }
    }, error => {
      if (error.error.code === 401 && error.error.result === 'Invalid otp') {
        this.otpSent = false;
        this.failOTP = true;
        this.loginSuccess = false;
        this.otpForm.reset();
        setTimeout(() => {
          const field1 = document.getElementById('otp1');
          const field2 = document.getElementById('otp2');
          const field3 = document.getElementById('otp3');
          const field4 = document.getElementById('otp4');
          field1.style.border = '1px solid #E30000';
          field2.style.border = '1px solid #E30000';
          field3.style.border = '1px solid #E30000';
          field4.style.border = '1px solid #E30000';
        }, 1000);
      } else if (error.error.code === 401 && error.error.result === 'Otp expired') {
        this.otpSent = false;
        this.failOTP = false;
        this.loginSuccess = false;
        this.timeExpire = true;
        this.timeExpireErr = 'Timeout! your OTP has been expired please login again.';
        setTimeout(() => {
          this.router.navigate(['/admin-login']);
        }, 5000);
      }
    });
  }
}
