import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import "rxjs/add/operator/do";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { TnNotificationService } from "../../app/services/tn-notification.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private notificationService: TnNotificationService
  ) {}
  token: any;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var token = localStorage.getItem("token");
    if (token == undefined || token.length == 0 || token == null) {
        alert('You are not Authorized to view This page!');
        // this.notificationService.errorToast(
        //     "You are not Authorized to view This page!"
        //   );
      this.router.navigate(["/admin-login"]);
      return false;
    }
    return true;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token"); // you probably want to store it in localStorage or something
    if (!token) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set("x-access-token", token)
    });
    // return next.handle(req1);
    return next.handle(req1).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // if the token is valid
        }
      },
      (err: any) => {
        console.log("tokenerror=====>", err);
        // if the token has expired.
        if (err instanceof HttpErrorResponse) {
          if ( err.status === 401) {
            // this.notificationService.errorToast(
            //   "Session expired. Please login again!"
            // );
            alert('Session expired. Please login again!');
            localStorage.clear();
            this.router.navigateByUrl("/admin-login");
          }
        }
      }
    );
  }
}
