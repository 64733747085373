import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { RegisterAdminComponent } from './set-password/register.component';
import { SettingsComponent } from './settings/settings.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { OtpComponent } from './otp/otp.component';
import { AuthGuard } from './services/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DeactivationRequestsComponent } from './deactivation-requests/deactivation-requests.component'
import { UserAccountsComponent } from './user-accounts/user-accounts.component';
// import { AssignRMComponent } from './assign-rm/assign-rm.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin-login',
    pathMatch: 'full'
  },
  {
    path: 'deactivation-requests',
    component: DeactivationRequestsComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  // {
  //   path: 'register-admin',
  //   component: RegisterAdminComponent
  // },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('../app/administration/administration.modules').then(
        m => m.AdministrationModule
      ),
    //canActivate: [AuthGuard]
  },
  {
    path: 'application-management',
    loadChildren: () =>
      import('../app/application-management/application-management.module').then(
        m => m.ApplicationManagementModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'post-issuance-management',
    loadChildren: () =>
      import('../app/issuance-management/issuance-management.module').then(
        m => m.IssuanceManagementModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'submissions',
    loadChildren: () =>
      import('../app/submissions/submissions.modules').then(
        m => m.SubmissionsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'compaigns-list',
    loadChildren: () =>
      import('../app/campaigns/campaigns.modules').then(m => m.CampaignsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account-users',
    loadChildren: () =>
      import('../app/account-users/account-users.module').then(
        m => m.AccountUsersModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'set-password',
    component: RegisterAdminComponent
  },
  {
    path: 'reset-password',
    component: CreatePasswordComponent
  },
  {
    path: 'packages',
    loadChildren: () =>
      import('../app/packages/packages.module').then(m => m.PackagesModule)
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-login',
    component: AdminLoginComponent
  },
  {
    path: 'otp',
    component: OtpComponent
  },
  // {
  //   path: 'assignRM',
  //   component: AssignRMComponent
  // }
  {
    path: 'user-account',
    component: UserAccountsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
