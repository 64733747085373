import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { query } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TnNotificationService } from '../services/tn-notification.service';
import { AdminServices } from '../services/admin.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordVerify: FormGroup;
  submit = false;
  emailId: any = '';
  user: any;
  emailFromJoin: any;
  forgotPasswordSuccess = false;
  errorMessage: string;
  noUser = false;
  socialMedia = false;
  notVerified = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private adminServices: AdminServices,
    public toastrService: ToastrService,
    private notificationService: TnNotificationService,
    private activatedRoute: ActivatedRoute,
    public route: ActivatedRoute
  ) { }

  ONCHANGE(e) {
    let re = /[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/;
    if (re.test(e.target.value)) {
      e.preventDefault();
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe((res: { email: string }) => {
      this.emailId = res.email;
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.emailFromJoin = data.params.email;
    });
    this.forgotPasswordVerify = this.formBuilder.group({
      email: ['', Validators.required]
    });


  }

  forgotPasswordMail(emailId) {
    this.submit = true;
    this.adminServices.forgotPasswordMail(emailId).subscribe(

      (response: any) => {
        if (response.code === 200) {
          this.noUser = false;
          this.notVerified = false;
          this.socialMedia = false;
          this.forgotPasswordSuccess = true;
          setTimeout(() => {
            this.router.navigateByUrl('/admin-login');
          }, 2000);
        }
      }, err => {
        this.forgotPasswordSuccess = false;
        if (err.error.code === 401 || err.error.code === 400) {
          this.noUser = true;
          this.socialMedia = false;
          this.notVerified = false;
          this.errorMessage = 'Please check the emailId';
        } else if (err.error.code === 406) {
          this.socialMedia = true;
          this.noUser = false;
          this.notVerified = false;
          this.errorMessage = 'User registered through Social media Login!';
        } else if (err.error.code === 417) {
          this.socialMedia = false;
          this.noUser = false;
          this.notVerified = true;
          this.errorMessage = 'User Email not verified!';
        }
      }
    );
  }
}
