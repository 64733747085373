import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-management',
  templateUrl: './application-management.component.html',
  styleUrls: ['./application-management.component.scss']
})
export class ApplicationManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
