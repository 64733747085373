import { Injectable } from "@angular/core";
import swal from "sweetalert2";
import { Observable, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class TnNotificationService {
  notificationsObs: ReplaySubject<any> = new ReplaySubject<any>();
  questsionsNotificationsObs: ReplaySubject<any> = new ReplaySubject<any>();
  warningNotificationObs: ReplaySubject<any> = new ReplaySubject<any>();
  public SucessData: any;

  constructor() { }

  // private notification(title, text, type) {
  //   swal(title, text, type);
  // }

  // private toast(title, type) {
  //   swal({
  //     position: "bottom-right",
  //     type: type,
  //     title: title,
  //     showConfirmButton: false,
  //     toast: true,
  //     timer: 3000
  //   });
  // }

  getNotification(): Observable<any> {
    return this.notificationsObs.asObservable() as Observable<any>;
  }

  getQuestionNotification(): Observable<any> {
    return this.questsionsNotificationsObs.asObservable() as Observable<any>;
  }

  getwarningNotificationObs(): Observable<any> {
    return this.warningNotificationObs.asObservable() as Observable<any>;
  }

  getUsersNotified(): Observable<any> {
    return this.notificationsObs.asObservable() as Observable<any>;
  }

  successNotificationValue(): any {
    return this.getUsersNotified().subscribe((res: any) => {
      this.SucessData = res;
    });
  }

  // infoNotification(text) {
  //   this.notification("Information", text, "info");
  // }

  // errorNotification(text) {
  //   this.notification("Error", text, "error");
  // }

  // warningNotification(text) {
  //   this.notification("Warning", text, "warning");
  // }

  // questionNotification(text) {
  //   this.notification("Question", text, "question");
  // }

  // successNotification(text) {
  //   this.notification("Success", text, "success");
  // }

  // infoToast(title) {
  //   this.toast(title, "info");
  // }

  // errorToast(title) {
  //   this.toast(title, "error");
  // }

  // warningToast(title) {
  //   this.toast(title, "warning");
  // }

  // questionToast(title) {
  //   this.toast(title, "question");
  // }

  // successToast(title) {
  //   this.toast(title, "success");
  // }
}
