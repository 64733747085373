import { Component } from '@angular/core';
import { AdminServices } from '../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { MustMatch } from '../helpers/must-watch.validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-register-admin',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterAdminComponent {
  registerPasswordForm: FormGroup;
  // [x: string]: any;
  password: string;
  activationCode: string;
  setPassword = {};
  setPasswordSuccess = false;
  noUser = false;
  errorMessage: string;
  linkExpired = false;
  constructor(
    private formBuilder: FormBuilder,
    public adminServices: AdminServices,
    public route: ActivatedRoute,
    public router: Router,
    public toastrService: ToastrService
  ) { }
  ONCHANGE(e) {
    var re = /[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/;
    if (re.test(e.target.value)) {
      e.preventDefault();
    }
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit(): void {
    this.route.queryParams.subscribe(res => {
      this.activationCode = res.activationCode;
    });

    this.registerPasswordForm = this.formBuilder.group(
      {
        password: [
          null,
          [Validators.required, Validators.compose([Validators.minLength(8)])]
        ],
        confirmPassword: ['', Validators.required]
      },
      {
        // validator: MustMatch("password", "confirmPassword")
      }
    );
  }

  onSubmit($event) {
    if (this.registerPasswordForm.value.password !== this.registerPasswordForm.value.confirmPassword) {
      this.registerPasswordForm.reset();
    } else {
      this.adminServices
        .setPassword({
          password: this.registerPasswordForm.value.password,
          activationCode: this.activationCode
        })
        .subscribe(response => {
          if (response && response.code === 200) {
            this.setPasswordSuccess = true;
            setTimeout(() => {
              this.router.navigate(['/admin-login']);
            }, 2000);
            // this.toastrService.success('Password Updated successfully');
          } else if (response.code === 208 && response.result === 'Link has been experied') {
            this.linkExpired = true;
            this.setPasswordSuccess = false;
            this.noUser = false;
            this.errorMessage = 'Link has been experied!';
          }
        }, error => {
          this.setPasswordSuccess = false;
          if (error.error.code === 404) {
            this.linkExpired = false;
            this.setPasswordSuccess = false;
            this.noUser = true;
            this.errorMessage = 'User not found';
            setTimeout(() => {
              this.router.navigate(['/admin-login']);
            }, 2000);
          }
        });

    }

  }
}
