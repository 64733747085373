import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  isSuperAdmin = false;
  role: string = localStorage.getItem("role");

  constructor(private router:Router) { }

  ngOnInit() {
    this.checkIsLoggedIn(this.role);
  }

  checkIsLoggedIn(role) {
    if (role == "superAdmin") {
      this.isSuperAdmin = true;
    }
  }

  logout(){
    localStorage.clear();
    this.router.navigate(["/admin-login"]);
  }
}
