import { Component, OnInit } from '@angular/core';
import { AdminServices } from '../services/admin.service';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";


import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.scss']
})
export class UserAccountsComponent implements OnInit {

  // campaignList: any = [];
  totalUsers: number = 0;
  userList: any = [];
  public page = 1;
  public limit: number = 3;
  public skip: number = 0;
  queryObj: any = {};
  searchText = "";



  public timeout;
  public searchTimeout = 1000;
  getAllCampaignData: any;
  campaignList: any;
  campaignApproveData: any;
  CampaignData: any = [];
  currentPage = 1;
  recordsperpage = 4;
  pages: any;

  currentpage: any = [];
  filterArray: any = [];

  startIndex: any;
  endIndex: any;

  obj: any = {};

  activationkey: boolean = false;
  count: number = 0;



  campaignTypes = [
    { value: 1, label: 'traditionalBonds' },
    { value: 2, label: 'traditionalEquity' },
    { value: 3, label: 'traditionalConvertibles' },
    { value: 4, label: 'stoBonds' },
    { value: 5, label: 'stoEquity' },
    { value: 6, label: 'stoConvertibles' },
    { value: 7, label: 'rewards' },
    { value: 8, label: 'donation' }
  ];

  companyStage = [
    { value: 1, label: 'preSeed' },
    { value: 2, label: 'seedStage' },
    { value: 3, label: 'lateStage' }
  ];

  // array: any = [
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', aassignedRM: 'Nicolas Brewer', status: 'NEW' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Published' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Rejected' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Pending' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Pending' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Pending' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Rejected' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Rejected' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Published' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Published' },
  //   { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },

  // ]


  constructor(private adminServices: AdminServices,
    public toastrService: ToastrService,
    private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    // this.changePage(1);
    for (let i = 0; i < this.userList.length; i++) {
      this.obj['keyvalue' + i] = 'deactivate';
    }
    // this.obj['keyvalue' + 0] = false;
    // this.activationkey = 'reactivate';

    this.getUsers({
      limit: this.limit,
      skip: this.skip
    });
  }

  getUsers(queryObj) {
    queryObj['skip'] = this.skip;
    queryObj['limit'] = this.limit;
    queryObj['searchText'] = this.searchText;
    this.adminServices.findAllUsers(queryObj).subscribe(res => {
      this.userList = res.result.rows;
      this.totalUsers = res.result.count;
    });
  }

  public loadPage(page) {
    this.skip = this.limit * (page - 1);
    this.queryObj.skip = this.skip;
    this.queryObj.limit = this.limit;
    this._changePage();
  }

  private _changePage() {
    this.getUsers(this.queryObj);
  }


  onSearch(text) {
    this.page = 1;
    this.skip = 0;
    this.searchText = text;
    this.getUsers(this.queryObj);
  }




  /* Pagination Starts Here */

  // numpages() {
  //   this.pages = Math.ceil(this.array.length / this.recordsperpage)
  //   console.log('pages', this.pages)
  //   for (let i = 0; i < this.pages; i++) {
  //     console.log(i)
  //     this.currentpage.push(i + 1);
  //   }
  //   console.log(this.currentpage)
  // }

  // changePage(pageNumber) {
  //   this.filterArray = [];
  //   this.startIndex = ((pageNumber - 1) * this.recordsperpage)
  //   this.endIndex = pageNumber * this.recordsperpage;
  //   if (this.endIndex > this.array.length) {
  //     this.endIndex = this.array.length
  //   }
  //   console.log(this.startIndex, this.endIndex)
  //   for (let i = this.startIndex; i < this.endIndex; i++) {
  //     this.filterArray.push(this.array[i]);
  //   }
  // }
  // prvPage() {
  //   if (this.currentPage > 1) {
  //     this.currentPage--;
  //     this.changePage(this.currentPage);
  //   }
  // }
  // nextPage() {
  //   if (this.currentPage < this.pages) {
  //     this.currentPage++;
  //     this.changePage(this.currentPage)
  //   }
  // }

  deactvate(key, index) {
    if (key === 'deactivate') {
      this.obj['keyvalue' + index] = 'reactivate';
      console.log(this.obj['keyvalue' + index]);
    }
    else if (key === 'reactivate') {
      this.obj['keyvalue' + index] = 'deactivate';
      console.log(this.obj['keyvalue' + index]);
    }
  }

}
