import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AdminServices {
  addCompanyDetails = environment.apiURL + '/issuer/addCompanyDetails';
  getSubmissions = environment.apiURL + '/issuer/getSubmission';
  getInvestorsCountAPI = environment.apiURL + '/admin/getInvestorsCount';
  getsubmissionDetails = environment.apiURL + '/admin/getApplicationById/';
  createAdminUrl = environment.apiURL + '/administrator/createAdmin';
  deleteAdminUrl = environment.apiURL + '/administrator/deleteAdmin/';
  updateAdminUrl = environment.apiURL + '/administrator/updateAdmin/';
  findAdminByUuidUrl = environment.apiURL + '/administrator/findAdminByUuid/';
  loginUrl = environment.apiURL + '/administrator/login';
  setPasswordUrl = environment.apiURL + '/administrator/setPassword';
  getAllAdmins = environment.apiURL + '/administrator/getAllAdmins';
  getAllRMList = environment.apiURL + '/administrator/getRMList';
  createPasswordUrl = environment.apiURL + '/administrator/reset-password';
  forgotPasswordMailUrl = environment.apiURL + '/administrator/forgotPasswordMail';
  forgotPasswordlUrl = environment.apiURL + '/administrator/forgotPassword';
  findAdminByActivationCodeUrl = environment.apiURL + '/administrator/findAdminByActivationCode/';
  changekycStatus = environment.apiURL + '/issuer/changeKycStatus';
  paymentAcceptance = environment.apiURL + '/issuer/paymentAcceptance';
  getAllUsers = environment.apiURL + '/user/getAllUsers';
  findUserById = environment.apiURL + '/user/adminuserById/';
  findById = environment.apiURL + '/user/fetchById/';
  profileImage = environment.apiURL + '/auth/adminGetImageByLocation';
  assignRM = environment.apiURL + '/issuer/assigntoRM';
  getInvestorDetails = environment.apiURL + '/administrator/investorById/';
  campaignList = environment.apiURL + '/campaign/getAllCampaigns';
  getCompanyKYC = environment.apiURL + '/kyc/getKYCForAdmin/';
  getDocuments = environment.apiURL + '/common/getImageByLocation';
  verify2FA = environment.apiURL + '/administrator/verify-2fa';
  checkEmail = environment.apiURL + '/administrator/checkEmail';
  getAllCamapignDataAdmin = environment.apiURL + '/masterCampaign/admin';
  getAllCamapignDataUser = environment.apiURL + '/masterCampaign/user/';
  getAllAssetsTokenization = environment.apiURL + '/assetsTokenization/getAll';
  getAllCampaignListing = environment.apiURL + '/campaignList/getAll';
  getAllCharity = environment.apiURL + '/charity/getAll';
  getAllPreSeed = environment.apiURL + '/preSeed/getAll';
  getAllRewardBased = environment.apiURL + '/reward/getAll';
  getAllSTO = environment.apiURL + '/sto/getAll';
  getAllTraditional = environment.apiURL + '/traditionalSecurity/getAll';
  private requestIdData =  new BehaviorSubject<object>({});
  private userDetails =  new BehaviorSubject<object>({});
  private campaignData =  new BehaviorSubject<object>({});

  constructor(private http: HttpClient, private router: Router) {}

  addCompany(body) {
    return this.http.post(this.addCompanyDetails, body);
  }
  getsubmissionData(queryParams) {
    return this.http.get<any>(this.getSubmissions, { params: queryParams });
  }
  getApplicationById(applicationId) {
    return this.http.get<any>(this.getsubmissionDetails + applicationId);
  }
  createAdmin(body) {
    return this.http.post<any>(this.createAdminUrl, body);
  }
  deleteAdmin(obj) {
    return this.http.post<any>(this.deleteAdminUrl, obj);
  }
  updateAdmin(body, uuid) {
    return this.http.patch<any>(this.updateAdminUrl + uuid, body);
  }
  findAdminByUuid(uuid) {
    return this.http.get<any>(this.findAdminByUuidUrl + uuid);
  }

  login(body) {
    return this.http.post<any>(this.loginUrl, body);
  }
  setPassword(body) {
    return this.http.post<any>(this.setPasswordUrl, body);
  }
  createPassword(body) {
    return this.http.post<any>(this.createPasswordUrl, body);
  }

  forgotPasswordMail(body) {
    return this.http.post<any>(this.forgotPasswordMailUrl, body);
  }

  forgotPasswordl(body) {
    return this.http.post<any>(this.forgotPasswordlUrl, body);
  }

  findAdminByActivationCode(params) {
    return this.http.get<any>(this.findAdminByActivationCodeUrl + params);
  }
  changeKycStatus(body) {
    return this.http.put<any>(this.changekycStatus, body);
  }
  companyPaymentAcceptanceStatus(body) {
    return this.http.put<any>(this.paymentAcceptance, body);
  }
  // findAllUsers(queryParams) {
  //   return this.http.get<any>(this.getAllUsers, { params: queryParams });
  // }
  findAllUsers(body) {
    return this.http.post<any>(this.getAllUsers,body);
  }

  getCompanyKyc(companyKYCId) {
    return this.http.get<any>(this.getCompanyKYC + companyKYCId);
  }
  findUserData(uuid) {
    return this.http.get<any>(this.findUserById + uuid);
  }
  findUserbyId(uuid) {
    return this.http.get<any>(this.findById + uuid);
  }

  getProfileImage(query) {
    return this.http.get<any>(
      this.profileImage + `?fileName=${query.fileName}`
    );
  }
  // getAllAdminsList(queryParams) {
  //   return this.http.get<any>(this.getAllAdmins, { params: queryParams });
  // }
  getAllAdmindata(body) {
    return this.http.post<any>(this.getAllAdmins, body);
  }
  getRMList() {
    return this.http.get<any>(this.getAllRMList);
  }

  assignToRm(body) {
    return this.http.put<any>(this.assignRM, body);
  }
  getInvestorCount() {
    return this.http.get<any>(this.getInvestorsCountAPI);
  }
  getInvestorData(uuid) {
    return this.http.get<any>(this.getInvestorDetails + uuid);
  }
  getCampaignList(queryParams) {
    return this.http.get<any>(this.campaignList, { params: queryParams });
  }
  getDocument(query) {
    return this.http.get<any>(
      this.getDocuments + `?fileName=${query.fileName}`
    );
  }

  // searchForCampaignName(value) {
  //   this.http;
  // }

  verify2FAOTP(body) {
    return this.http.post<any>(this.verify2FA, body);
  }

  setRequestId(requestId: object) {
    this.requestIdData.next(requestId);
  }

  getRequestId(): Observable<any> {
    return this.requestIdData.asObservable();
  }

  setUserDetails(data: object) {
    this.userDetails.next(data);
  }

  getUserDetails(): Observable<any> {
    return this.userDetails.asObservable();
  }

  checkUserEmail(body) {
    return this.http.post(this.checkEmail, body);
  }


  getAllCampaignUser(body, userId) {
    return this.http.post<any>(this.getAllCamapignDataUser + userId, body);
  }
  setcampaignrowData(campaignData: object) {
    this.campaignData.next(campaignData);
  }
  getCampaignrowData(): Observable<any> {
    return this.campaignData.asObservable();
  }


  getAllCampaignAdmin(body) {
    return this.http.post<any>(this.getAllCamapignDataAdmin, body);
  }

  getAllAssetCampaign(body) {
    return this.http.post<any>(this.getAllAssetsTokenization, body);
  }

  getAllListingCampaign(body) {
    return this.http.post<any>(this.getAllCampaignListing, body);
  }

  getAllCharityCampaign(body) {
    return this.http.post<any>(this.getAllCharity, body);
  }

  getAllPreSeedCampaign(body) {
    return this.http.post<any>(this.getAllPreSeed, body);
  }

  getAllRewardBasedCampaign(body) {
    return this.http.post<any>(this.getAllRewardBased, body);
  }

  getAllSTOCampaign(body) {
    return this.http.post<any>(this.getAllSTO, body);
  }

  getAllTraditionalCampaign(body) {
    return this.http.post<any>(this.getAllTraditional, body);
  }

}
