import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { CommonModulesModule } from "./common-modules/common-modules.module";
import { AdministrationComponent } from "./administration/administration.component";
import { CampaignsComponent } from "./campaigns/campaigns.component";
import { SettingsComponent } from "./settings/settings.component";
import { SubmissionsComponent } from "./submissions/submissions.component";
import { TransactionsComponent } from "./transactions/transactions.component";
import { AdminServices } from "./services/admin.service";
import { AuthGuard } from "./services/auth.guard";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientModule } from "@angular/common/http";
import { CreatePasswordComponent } from "./create-password/create-password.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RegisterAdminComponent } from './set-password/register.component';
import { AdminLoginComponent } from "./admin-login/admin-login.component";
// import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
// import { NgxEditorModule } from "ngx-editor";
// import { NgxEditorModule } from 'ngx-editor';
// import { ToastrService } from "ngx-toastr";
// import { NgbTabsetConfig } from "@ng-bootstrap/ng-bootstrap";
// import {TnNotificationService} from './services/tn-notification.service';
// import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
// import { IssuerService } from "./services/issuer.service";

import { PopoverModule } from 'ngx-bootstrap/popover';
import { OtpComponent } from './otp/otp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
// import { AssignRMComponent } from './assign-rm/assign-rm.component';
import { ApplicationManagementComponent } from './application-management/application-management.component';
import { DeactivationRequestsComponent } from './deactivation-requests/deactivation-requests.component';
import { UserAccountsComponent } from './user-accounts/user-accounts.component';
// import { CampaignTeammembersComponent } from './campaigns/campaign-details/campaign-teammembers/campaign-teammembers.component';
//import { CampaignDetailsMenuComponent } from './campaigns/campaign-details/campaign-details-menu/campaign-details-menu.component';
//import { CampaignDetailsHeadingComponent } from './campaigns/campaign-details/campaign-details-heading/campaign-details-heading.component';
//import { CampaignDetailsComponent } from './campaigns/campaign-details/campaign-details.component';
// import { PublishingRequestsComponent } from './campaigns/publishing-requests/publishing-requests.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AdministrationComponent,
    SettingsComponent,
    TransactionsComponent,
    SubmissionsComponent,
    CreatePasswordComponent,
    CampaignsComponent,
    RegisterAdminComponent,
    AdminLoginComponent,
    OtpComponent,
    ForgotPasswordComponent,
    // AssignRMComponent,
    ApplicationManagementComponent,
    DeactivationRequestsComponent,
    UserAccountsComponent,
    // CampaignTeammembersComponent,
    // CampaignDetailsMenuComponent,
    // CampaignDetailsHeadingComponent,
    // CampaignDetailsComponent,
    // PublishingRequestsComponent,
    // ToastrService,
    // NgbTabsetConfig,
    // TnNotificationService,
    // IssuerService

  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    CommonModulesModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true
    }),
    // NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [AdminServices,
    { provide: HTTP_INTERCEPTORS, useClass: AuthGuard, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
