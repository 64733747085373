import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AdminServices } from '../services/admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: [`./admin-login.component.scss`]
})
export class AdminLoginComponent {
  email: string;
  password: string;
  // admin = {}
  admin = {
    // role:'',
    email: '',
    password: ''
  };
  Roles = [{ role: 'operationalManager', value: 1 },
  { role: 'relationshipManager', value: 2 }];
  show: boolean;
  loginError: any = '';
  loginSuccess = false;
  userNotFound: boolean;
  userNotError: string;
  invalidUserPass = false;
  invalidUserError: string;
  rememberMe: boolean;
  regex = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  validEmail: boolean;
  tickImg: any;
  emailExists: boolean;
  emailNotExist: boolean;
  constructor(private router: Router,
              public adminServices: AdminServices,
              public toastrService: ToastrService) { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    setTimeout(() => {
      this.tickImg = document.getElementById('tickImg');
      this.tickImg.style.display = 'none';
    }, 500);
  }

  onSubmit() {
    // const query = {
    //   name: data.name,
    //   email: data.email,
    //   phoneNumber: data.phoneNumber,
    //   role: (data.Permission.value === 1 ? 'operationalManager' : (data.Permission.value === 2) ? 'relationshipManager' :
    //     (data.Permission.value === 3) ? 'chatSupport' : 'admin')
    // };
    if (this.admin.email) {
      const query = {
         email: this.admin.email,
         password: this.admin.password,
        //  role:(this.admin.role === '1' ? 'operationalManager':this.admin.role === '2' ? 'relationshipManager':'')
      };
      this.adminServices.login(query).subscribe((response) => {
        if (response && response.code === 200) {
          this.loginSuccess = true;
          this.userNotFound = false;
          this.invalidUserPass = false;
          // tslint:disable-next-line: no-string-literal
          localStorage.setItem('requestId', response['result'].requestId);
          // tslint:disable-next-line: no-string-literal
          this.adminServices.setRequestId(response['result']);
          // tslint:disable-next-line: no-string-literal
          localStorage.setItem('userId', response['result'].userId);
          this.router.navigate(['/otp']);
        }
      }, error => {
        const userName = document.getElementById('email');
        const pass = document.getElementById('password');
        this.loginSuccess = false;
        if (error.error.code === 404 && error.error.result === 'Email not found') {
          // this.toastrService.error('User not found!');
          this.userNotFound = true;
          this.userNotError = 'Your email is not registered as admin.';
          userName.style.border = '1px solid #E30000';
        } else if (error.error.code === 401 && error.error.result === 'Wrong email or Password') {
          // this.toastrService.error('Wrong email or Password!')
          this.invalidUserPass = true;
          this.invalidUserError = 'The password you enetered is incorrect.';
          userName.style.border = '1px solid #E30000';
          pass.style.border = '1px solid #E30000';
          this.tickImg.style.display = 'block';
        } else if (error.error.code === 417 && error.error.result === 'Role not matched') {
          // this.toastrService.error('Role not matched')
          this.loginError = 'Role not matched';
        }
      });
    } else {
      // this.toastrService.error('Please enter email!');
      this.loginError = 'Role not matched';
    }
    // e.preventDefault();

  }

  passwordToggle() {
    this.show = !this.show;
  }

  validateEmail(val) {
    if (val.match(this.regex)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
  }

  maskPass() {
    const passField = document.getElementById('password');
    passField.setAttribute('type', 'text');
    setTimeout(() => {
      passField.setAttribute('type', 'password');
    }, 2000);
  }

  RememberMe(event) {
    this.rememberMe = event.target.checked;
    if (this.rememberMe === true) {
      sessionStorage.setItem('email', this.admin.email);
      sessionStorage.setItem('password', this.admin.password);
      // sessionStorage.setItem('booleanvalue', this.rememberMe);
    } else if (this.rememberMe === false) {
      sessionStorage.clear();
    }
  }

  checkEmail(email) {
    const verifyEmailBody = {
      // tslint:disable-next-line: object-literal-shorthand
      email: email
    };
    this.adminServices.checkUserEmail(verifyEmailBody).subscribe((response: any) => {
      console.log(response.code);
    },
      err => {
        if (err.error.code === 403) {
          this.emailExists = true;
          this.emailNotExist = false;
        } else if (err.error.code === 404) {
          this.emailExists = false;
          this.emailNotExist = true;
          this.userNotError = 'Your email is not registered as admin.';
        }
      });
  }
}
