import { Component, OnInit } from "@angular/core";
import { AdminServices } from "../services/admin.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  constructor(private adminservice: AdminServices) {}
  count: string;
  ngOnInit() {
    this.adminservice.getInvestorCount().subscribe(res => {
      this.count = res.result.documentsCount;
    });
  }
}
