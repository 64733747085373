import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminServices } from '../services/admin.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  createPasswordForm: FormGroup;
  submit = false;
  createPasswordSuccess = false;
  noUser = false;
  errorMessage: string;
  linkExpired = false;

  activationCode: any;

  ONCHANGE(e) {
    var re = /[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/;
    if (re.test(e.target.value)) {
      e.preventDefault();
    }
  }
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminServices
  ) {
  }

  ngOnInit() {

    this.createPasswordForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.compose([
        Validators.minLength(8)])]],
      confirmPassword: ['', Validators.required],
    });

    this.activationCode = this.route.snapshot.queryParams.activationCode;
    this.adminService.findAdminByActivationCode(this.activationCode).subscribe((res) => {
    },
      error => {
        if (error.error.code === 404) {
          this.linkExpired = false;
          this.createPasswordSuccess = false;
          this.noUser = true;
          this.errorMessage = 'link has been expired';
          setTimeout(() => {
            this.router.navigate(['/admin-login']);
          }, 4000);
        }
      });
  }


  createPassword(params) {
    const queryParams = {
      activationCode: this.activationCode,
      password: params.password
    };
    this.adminService.forgotPasswordl(queryParams).subscribe((response: any) => {
      this.createPasswordSuccess = true;
      this.noUser = false;
      setTimeout(() => {
        this.router.navigate(['/admin-login']);
      }, 4000);

    }, error => {
      this.createPasswordSuccess = false;
      if (error.error.code === 403) {
        this.linkExpired = false;
        this.createPasswordSuccess = false;
        this.noUser = true;
        this.errorMessage = 'Old password is not accepted!';
        // setTimeout(() => {
        //    this.router.navigate(['/admin-login']);
        //  }, 4000);
      }

      if (error.error.code === 404) {
        this.linkExpired = false;
        this.createPasswordSuccess = false;
        this.noUser = true;
        this.errorMessage = 'link has been expired';
        setTimeout(() => {
          this.router.navigate(['/admin-login']);
        }, 4000);
      }
    });
  }

}
