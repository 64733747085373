import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
	submit: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
		private router: Router,
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
			email: ["", Validators.required],
			password: ["", Validators.required]
		});
  }
  login(data) {
		this.submit = true;
		// this._authService.login(data).subscribe((response) => {
		// 	this._authService.storeDetailsAfterLogin(response.result);
		// 	this.router.navigate(['/programs'])
		// }, (error) => {
		// 	this.submit = false;
		// 	this._messageHandler.errorMessage(error.error.message || ErrorMessage.commonError);
		// });
	}
}
