import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deactivation-requests',
  templateUrl: './deactivation-requests.component.html',
  styleUrls: ['./deactivation-requests.component.scss']
})
export class DeactivationRequestsComponent implements OnInit {

  // campaignList: any = [];
  totalCampaigns: number = 0;
  public page = 1;
  public limit: number = 1;
  public skip: number = 0;
  queryObj: any = {};
  public timeout;
  public searchTimeout = 1000;
  getAllCampaignData: any;
  campaignList: any;
  campaignApproveData: any;
  CampaignData: any = [];
  searchText = "";
  currentPage = 1;
  recordsperpage = 4;
  pages: any;

  currentpage: any = [];
  filterArray: any = [];

  startIndex: any;
  endIndex: any;

  obj: any = {};

  activationkey: boolean = false;
  count: number = 0;



  campaignTypes = [
    { value: 1, label: 'traditionalBonds' },
    { value: 2, label: 'traditionalEquity' },
    { value: 3, label: 'traditionalConvertibles' },
    { value: 4, label: 'stoBonds' },
    { value: 5, label: 'stoEquity' },
    { value: 6, label: 'stoConvertibles' },
    { value: 7, label: 'rewards' },
    { value: 8, label: 'donation' }
  ];

  companyStage = [
    { value: 1, label: 'preSeed' },
    { value: 2, label: 'seedStage' },
    { value: 3, label: 'lateStage' }
  ];

  array: any = [
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', aassignedRM: 'Nicolas Brewer', status: 'NEW' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Published' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Rejected' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Pending' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Pending' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Pending' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Rejected' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Rejected' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Published' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'Published' },
    { name: 'Winged Monogram', accountUser: 'Brandon Brewer', stage: 'Pre seed', type: 'STO', acceptedDate: '18 / 12 / 2022', launchDate: '18 / 12 / 2022', assignedRM: 'Nicolas Brewer', status: 'NEW' },

  ]


  constructor() { }

  ngOnInit() {
    this.changePage(1);
    for (let i = 0; i < this.array.length; i ++) {
      this.obj['keyvalue' + i] = 'deactivate';
    }
    // this.obj['keyvalue' + 0] = false;
    // this.activationkey = 'reactivate';
  }

  /* Pagination Starts Here */

  numpages() {
    this.pages = Math.ceil(this.array.length / this.recordsperpage)
    console.log('pages', this.pages)
    for (let i = 0; i < this.pages; i++) {
      console.log(i)
      this.currentpage.push(i + 1);
    }
    console.log(this.currentpage)
  }

  changePage(pageNumber) {
    this.filterArray = [];
    this.startIndex = ((pageNumber - 1) * this.recordsperpage)
    this.endIndex = pageNumber * this.recordsperpage;
    if (this.endIndex > this.array.length) {
      this.endIndex = this.array.length
    }
    console.log(this.startIndex, this.endIndex)
    for (let i = this.startIndex; i < this.endIndex; i++) {
      this.filterArray.push(this.array[i]);
    }
  }
  prvPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.changePage(this.currentPage);
    }
  }
  nextPage() {
    if (this.currentPage < this.pages) {
      this.currentPage++;
      this.changePage(this.currentPage)
    }
  }

  deactvate(key, index) {
    if (key === 'deactivate') {
      this.obj['keyvalue' + index] = 'reactivate';
    }
    else if (key === 'reactivate') {
      this.obj['keyvalue' + index] = 'deactivate';
    }
  }

}
